export const footer = (theme) => ({
  footerContainer: {
    //marginTop: theme.spacing(4), // FIX ** apply margin top as default theme
    backgroundColor: theme.customPalette.primaryGreenG02,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      //marginTop: theme.spacing(0),
    },
  },
  damIt: {
    width: '30px !important',
    height: '30px !important',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  logo: {
    height: '100%',
    maxWidth: 272,
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 130,
    },
  },
  socialContainer: {
    maxWidth: 1500,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(7, 11, 4, 11),
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderColor: theme.customPalette.primaryGreenG02,
    borderWidth: '0 0 1px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderWidth: 0,
    },
  },
  socialTitleContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 7, 4, 7),
    },
  },
  socialLinkContainer: {
    display: 'flex',
    maxWidth: 272,
    width: 'auto',
    justifyContent: 'space-between',
    marginTop: 24,
    marginLeft: 0,
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 130,
      marginBottom: 40,
      marginLeft: 'auto',
    },
  },
  iconLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      width: 32,
      height: 32,
      [theme.breakpoints.down('sm')]: {
        width: 24,
        height: 24,
      },
    },
  },
  accordion: {
    backgroundColor: theme.customPalette.primaryGreenG02,
    margin: 0,
    paddingBottom: 15,
    '@media screen and (min-width: 960px)': {
      display: 'none',
    },
  },
  accordionSummary: {
    borderBottom: `.5px solid ${theme.customPalette.neutralGr01}`,
    color: theme.customPalette.neutralGr01,
    padding: 0,
    '& .MuiIconButton-root': {
      color: theme.customPalette.neutralGr01,
    },
  },
  accordionSummaryHeader: {
    fontFamily: 'Poppins',
    marginTop: 0,
    fontSize: 18,
    fontWeight: 600,
  },
  '.MuiIconButton-root': {
    color: theme.customPalette.neutralGr01,
  },
  accordionDetails: {
    paddingLeft: 0,
  },
  accordionSummaryBody: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  '.MuiAccordionDetails-root': {
    paddingLeft: 0,
  },
  accordionLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  accordionLinkText: {
    fontFamily: 'Poppins !important',
    textDecoration: 'none',
    color: theme.customPalette.neutralGr01,
    fontSize: 17,
  },
  chooseCountry: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: theme.spacing(3),
    color: '#fff !important',
    fontSize: 12,
  },
  countrySelectedContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  countrySelected: {
    fontFamily: 'Poppins',
    color: '#fff !important',
    fontWeight: 600,
    marginLeft: 10,
    marginRight: 10,
  },
  linksTitle: {
    fontFamily: 'Poppins',
    color: theme.customPalette.primaryGreenG03,
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 600,
  },
  linkListContainer: {
    padding: '14px 0 40px 0',
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
  linkList: {},
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: '#fff !important',
    marginRight: 64,
    fontWeight: 'initial',
    '&:visited': {
      color: '#fff !important',
    },
  },
  linkText: {
    fontFamily: 'Poppins',
    color: '#fff !important',
    fontSize: 18,
  },
  linkTextCopyright: {
    fontFamily: 'Poppins',
    color: theme.customPalette.primaryGreenG02,
    fontWeight: 500,
  },
  collectionLinksContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  collectionChunkContainer: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  socialIcon: {
    display: 'flex',
    maxHeight: 38,
    justifyContent: 'center',
  },
  borderLeft: {
    borderStyle: 'solid',
    borderColor: theme.customPalette.primaryGreenG02,
    borderWidth: '0 0 0 1px',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      borderWidth: 0,
    },
  },
  paymentsContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderColor: theme.customPalette.primaryGreenG02,
    backgroundColor: theme.customPalette.primaryGreenG03,
    borderWidth: '0 0 1px 0',
    padding: theme.spacing(4, 0),
  },
  paymentElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  paymentIcon: {
    height: 21,
    marginLeft: 14,
    marginRight: 14,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      marginLeft: 7,
      marginRight: 7,
    },
  },
  lastIcon: {
    height: '34px!important',
    [theme.breakpoints.down('sm')]: {
      height: '28px!important',
    },
  },
  securePaymentContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  securePaymentText: {
    color: theme.customPalette.primaryGreenG02,
    whiteSpace: 'pre-line',
    paddingLeft: theme.spacing(1),
    textAlign: 'left',
    lineHeight: '1.3 !important',
  },
  copyrightContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.customPalette.primaryGreenG03,
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  copyrightText: {
    display: 'flex',
    paddingBottom: 16,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.customPalette.primaryGreenG02}`,
      marginBottom: 8,
    },
  },
  copyrightLinks: {
    display: 'flex',
    justifyContent: 'center',
    wordBreak: 'break-word',
    overflowX: 'scroll',
  },
  copyrightElement: {
    color: theme.customPalette.primaryGreenG02,
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  copyrightTextClass: {
    fontFamily: 'Poppins',
    fontSize: 14,
    color: theme.customPalette.primaryGreenG02,
  },
});

export const tutorials = (theme) => ({
  noPackageClosed: {
    backgroundColor: '#f2f2f2',
    padding: '48px 32px',
    margin: '32px 0px',
  },
  package: {
    marginBottom: theme.spacing(8),
  },
  orderSumContainer: {},
  mainTitle: {
    color: theme.customPalette.primaryGreenG02,
    fontSize: 30,
    fontWeight: 600,
    fontFamily: 'Poppins',
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      paddingTop: theme.spacing(10),
    },
    maxWidth: 1280,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  sectionTitle: {
    margin: theme.spacing(3),
  },

  collectionContainer: {
    maxWidth: 1390,
    // marginLeft: "auto",
    // marginRight: "auto",
    '@media screen and (min-width: 960px) and (max-width: 1440px)': {
      // marginLeft: theme.spacing(4),
      // marginRight: theme.spacing(4),
    },
    '@media screen and (max-width: 1440px)': {
      maxWidth: 'unset',
    },
  },
  figuresGridContainer: {},
  nextPackageContainer: {
    margin: '0px 24px',
  },
  videoClass: {
    height: 234,
    [theme.breakpoints.up('md')]: {
      height: '285px',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '305px',
      width: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '335px',
      width: '100%',
    },
  },
  grid: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      // padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  gridMobile: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  noMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0!important',
    },
  },
  noMargin2: {
    marginBottom: '5px!important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0!important',
    },
  },
  selectRoot: {
    fontWeight: 400,
    padding: '0!important',
    textAlign: 'left',
    marginLeft: 8,
  },
  selector: {
    width: 225,
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      width: '100%',
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
  },
  icon: {
    width: 32,
    height: 32,
    top: 'calc(50% - 16px)',
  },
  iconOpen: {
    '-webkit-transform': 'rotateX(180deg)',
    '-ms-transform': 'rotateX(180deg)',
    transform: 'rotateX(180deg)',
  },
  titleWrapper: {
    //marginTop: "100px",
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '0px',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  openVideoTutorialsContainer: {
    marginTop: theme.spacing(7),
    padding: '58px 56px',
    backgroundColor: theme.customPalette.neutralGr02,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      padding: '0 16px',
    },
  },
  giftBoxes: {
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  packageTitle: {
    fontSize: 20,
    marginTop: 30,
    fontWeight: 600,
    textAlign: 'center',
    fontFamily: 'Poppins',
    margin: 0,
    color: theme.customPalette.neutralGy05,
    lineHeight: '1.1',
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      textAlign: 'left',
    },
  },
  packageSequence: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    display: 'block',
  },
  videoBox: {
    padding: theme.spacing(1),
  },
  packageContainer: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(10),
    },
  },
  issuesTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    display: 'block',
    paddingLeft: 8,
    paddingRight: 8,
    margin: 0,
    marginBottom: 16,
    marginTop: 24,
    lineHeight: '1.1',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      textAlign: 'left',
    },
  },
  serviceContainer: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(10),
    },
  },
  spaceBottom: { marginBottom: theme.spacing(10) },
  giftBG: {
    paddingLeft: '0.5px',
    paddingRight: '0.5px',
  },
  giftsImg: {
    maxWidth: '100%',
    height: '290px',
    backgroundColor: theme.customPalette.neutralGr01,
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  giftsText: {
    backgroundColor: theme.customPalette.neutralGr02,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  giftTitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
  },
  giftSubtitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 500,
  },
  instructionsGuide: {
    width: '100%',
    height: '180px',

    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },
  instructionsBtn: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      lineHeight: 1,
      paddingLeft: 8,
    },
    '& img': {
      filter: 'brightness(0) invert(1)',
    }
  },
  instructionsTitle: {
    fontSize: 20,
    color: theme.customPalette.neutralGy05,
    fontWeight: 600,
    margin: 0,
    fontFamily: 'Poppins',
  },

  instructionsDesc: {
    fontSize: 16,
    color: theme.customPalette.neutralGy05,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    margin: 0,
    marginBottom: 8,
  },
  instructionsContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  swiperButtons: {
    transform: 'translateY(-50%)',
    position: 'relative',
    zIndex: '2',
    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  prevIcon: {
    transform: 'rotate(180deg)',
  },
});

export const collection = (theme) => ({
  // NEW
  issuesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // backgroundColor: theme.customPalette.neutralGr01,
  },
  noPackageClosed: {
    backgroundColor: '#f2f2f2',
    padding: '48px 32px',
    margin: '32px 0px',
  },
  giftBG: {
    paddingLeft: '0.5px',
    paddingRight: '0.5px',
  },
  indexSelect: {
    '& .MuiSelect-select': {
      padding: '16px 20px',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
      marginBottom: '-5px',
    },
  },
  issuesGrid: {
    flexBasis: '50%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '20%',
    },
  },
  issuesBox: {
    height: '460px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: ' center',
    backgroundColor: '#fbfbfb',
  },
  issuesImg: {
    maxWidth: '100%',
    height: '300px',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  issuesName: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
  },
  issuesLinkDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.customPalette.secondaryYellowYButtons,
    // padding: "10px 20px",
    padding: '10px 0',
    wordBreak: 'break-all',
    borderRadius: '5px',
    marginTop: 16,
    '& :focus': {
      backgroundColor: theme.customPalette.secondaryYellowY03,
    },
    '& p': {
      textTransform: 'Capitalize',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      marginBottom: '-5px',
      color: '#fff',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& img': {
      width: '8px !important',
      height: '8px !important',
    },
  },
  giftsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(5),
  },
  giftsImg: {
    maxWidth: '100%',
    height: '290px',
    backgroundColor: theme.customPalette.neutralGr02,
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  giftsText: {
    backgroundColor: theme.customPalette.neutralGr01,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  giftTitle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
  },
  giftSubtitle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 500,
  },
  maiTitle: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    marginTop: '40px',
    display: 'block',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
  },
  // OLD
  package: {
    marginBottom: theme.spacing(8),
  },
  sectionTitle: {
    margin: theme.spacing(3),
  },
  collectionContainer: {
    maxWidth: 1390,
    margin: 'auto',
    backgroundColor: '#fff',
  },
  figuresGridContainer: {},
  nextPackageContainer: {
    margin: '0px 24px',
  },
  extra: {
    margin: '80px auto 100px auto',
    '@media (min-width: 501px) and (max-width: 1208px)': {
      margin: '80px 16px 100px 16px',
    },
    '@media (max-width: 500px)': {
      margin: '80px 10px 100px 10px',
    },
  },
});

export const helpCenter = (theme) => ({
  progress: {
    margin: '80px auto 80px auto',
  },
  cmdSelect: {
    maxWidth: '90vw !important',
    width: '100%',
  },
  faqs: {
    margin: theme.spacing(10, 0),
  },
  contactsTopicForm: {
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(10),
    },
  },
  faqsContainer: {
    backgroundColor: theme.customPalette.neutralGr01,
    marginTop: theme.spacing('0'),
    paddingTop: theme.spacing('4'),
    paddingBottom: theme.spacing('5'),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing('5'),
      paddingTop: theme.spacing('8'),
      paddingBottom: theme.spacing('10'),
    },
  },
  topicContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  topicUpTitle: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  topicMainTitle: {
    fontSize: 30,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.primaryGreenG02,
    margin: 0,
  },
  topictopicDescr: {
    fontSize: 18,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  topicSelect: {
    '& .MuiSelect-select': {
      color: theme.customPalette.neutralGr04,
      fontSize: 16,
      fontWeight: 'normal',
      // padding: "10px 20px",
      marginBottom: '-5px',
    },
    '& .MuiInputLabel-filled': {
      color: theme.customPalette.neutralGr04,
      fontSize: 16,
      fontWeight: 'normal',
      marginBottom: '-5px',
      // transform: "translate(20px, 16px) scale(1)",
    },
  },
});

export const login = (theme) => ({
  activateYourAccountBox: {
    position: 'relative',
    '& hr': {},
  },
  activateYourAccountText: {
    position: 'absolute',
    zIndex: 2,
    top: '15%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#fff',
    width: '75%',
    textAlign: 'center',

    '& a': {
      color: '#FF3366',
      fontWeight: 'bold',
      textDecoration: 'none',
      fontSize: 14,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
    gap: 16,
    [theme.breakpoints.up('md')]: {
      paddingTop: 32,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 32,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 32,
    },
  },
  iconPass: {
    width: 24,
    height: 24,
  },
  divider: {
    position: 'relative',
    padding: '32px 0px',
    '& span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#fff',
      display: 'block',
      width: 100,
      textAlign: 'center',
    },
  },
  secondTitle: {
    fontSize: 24,
    fontWeight: 700,
  },
  socialLogin: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundColor: '#f2f2f2',
    padding: 16,
    minHeight: '56px',
    [theme.breakpoints.down('sm')]: {},
    '&:hover': {
      backgroundColor: '#f2f2f2 !important',
    },
  },
  socialLoginFacebook: {
    '& img': {
      borderRadius: '50%',
      backgroundColor: '#1B74E3',
    },
  },
  root: {
    width: 400,
    display: 'block',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: 0,
    gap: 16,
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 768px)': {
      width: '100%',
    },
  },
  title: {
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
  },
  input: {
    width: '100%',
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-adornedEnd': {},
  },
  errorText: {
    //FIXME
    marginTop: 8,
    fontSize: 16,
    color: '#f93f3f',
  },
  forgotPass: {
    color: theme.customPalette.secondaryYellowYButtons,
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 16,
    marginBottom: 16,
  },
  forgotPasswordContainer: {
    width: 'fit-content',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  button: {
    width: '100%',
    fontWeight: 400,
    minHeight: 52,
    padding: '0 16px',
    textTransform: 'none',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
    '& img': {
      width: 24,
      height: 24,
    },
  },
  keepmein: {
    fontSize: 14,
    paddingLeft: 8,
    fontFamily: 'Poppins',
  },
  keepmeinContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export const loginAgent = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    width: 400,
    display: 'block',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: 0,
    '@media screen and (max-width: 768px)': {
      width: '100%',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
  },
  input: {
    width: '100%',
  },
  errorText: {
    //FIXME
    marginTop: 8,
    fontSize: 16,
    color: '#f93f3f',
  },
  forgotPass: {
    color: theme.customPalette.secondaryYellowYButtons,
    fontWeight: 'bold',
    fontSize: 14,
    textDecoration: 'underline',
    marginTop: 16,
    marginBottom: 16,
  },
  forgotPasswordContainer: {
    width: 'fit-content',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    fontWeight: 400,
    minHeight: 48,
    textTransform: 'capitalize',
  },
  keepmein: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  keepmeinContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    '@media screen and (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export const publicContactUs = (theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
    },
  },
  progress: {
    margin: '80px auto 80px auto',
  },
  title: {
    textAlign: 'center',
  },
});

export const publicHelpCenter = (theme) => ({
  progress: {
    margin: '120px auto 120px auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
    },
  },
  faqs: {
    margin: theme.spacing(15, 0, 20, 0),
  },
  title: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    width: '100%',
  },
  banner: {
    marginTop: theme.spacing(15),
    /*        display: "flex",
        alignItems: "center",
        maxHeight: 447,
        minHeight: 360,
        height: "100%",
        width: "100%"*/
  },
});

export const resetPassword = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  root: {
    width: 400,
    display: 'block',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: 0,
    '@media screen and (max-width: 768px)': {
      width: '100%',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    marginBottom: theme.spacing(5),
  },
  typ: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  input: {
    width: '100%',
  },
  errorText: {
    //FIXME
    marginTop: 8,
    fontSize: 16,
    color: '#f93f3f',
  },
  successBox: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    maxWidth: '36px !important',
    height: '36px !important',
  },
  button: {
    width: '100%',
    fontWeight: 400,
    minHeight: 48,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    '& span': {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      color: '#fff',
      '& img': {
        width: 24,
        height: 24,
      },
    },
  },
});

export const subscription = (theme) => ({
  package: {
    marginBottom: theme.spacing(8),
  },
  paddingRightRE: {
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingRight: '14%',
    },
  },
  modelAccordionSummary: {
    position: 'absolute',
    top: '100%',
    left: 0,
    minHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    border: '.5px solid' + theme.customPalette.neutralGr03,
    borderTop: 'none',
    width: '100%',
  },
  sectionTitle: {
    margin: theme.spacing(6, 0, 5, 0),
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    marginBottom: 24,
    marginTop: 24,
    display: 'block',
  },
  wrapper: {
    display: 'flex',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  accordionStyle: {},
  accordionSummary: {
    fontFamily: 'Poppins',
    border: '0.5px solid' + theme.customPalette.neutralGr04,
  },
  accordionDetails: {
    border: '0.5px solid' + theme.customPalette.neutralGr04,
    borderTop: 'none',
    padding: '0',
  },
  shipmentsWrapper: {
    width: '70%',
    paddingRight: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  subscriptionWrapper: {
    // width: "30%",
    // [theme.breakpoints.down("sm")]: {
    //     width: "100%",
    // },
    // marginTop: theme.spacing(13),
  },
  packagesGrid: {
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(16),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(18),
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(20),
    },
  },
  nextShipBox: {
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: theme.customPalette.neutralGr01,

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
      backgroundColor: '#fff',
    },
  },
  stepperBox: {
    backgroundColor: 'transparent !important',
    transform: 'translateY(5%)',
    paddingRight: '0',
    paddingLeft: '0',
    '& .MuiStepConnector-alternativeLabel': {
      backgroundColor: theme.customPalette.neutralGr03,
      // left: "calc(-30% + 20px)",
      // right: "calc(70% + 20px)",
      height: '1px',
    },
    '& .MuiStep-alternativeLabel': {
      '& span': {
        transform: 'translateY(-5%)',
        border: 'none',
      },
    },
    '& .MuiStepConnector-alternativeLabel.Mui-disabled': {
      backgroundColor: theme.customPalette.neutralGr03,
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-completed': {
      backgroundColor: theme.customPalette.secondaryYellowYButtons,
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-active': {
      backgroundColor: theme.customPalette.secondaryYellowYButtons,
    },
  },
  nextShipTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  nextShipTracking: {
    '& p': {
      color: theme.customPalette.secondaryYellowYButtons,
      fontSize: '10px',
      fontWeight: 'bold',
      border: 'none',
      textTransform: 'capitalize',
    },
  },

  // Full Kit
  fullKit: {
    backgroundColor: theme.customPalette.neutralGr01,
  },
  fullKitContainer: {
    padding: theme.spacing(7),
  },
  FKMainTitle: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  videoClass: {
    height: 234,
    [theme.breakpoints.up('md')]: {
      height: '285px',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '305px',
      width: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '335px',
      width: '100%',
    },
  },
  assembledTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  assembledContent: {
    maxWidth: '220px',
    width: '100%',
    textAlign: 'right',
  },
  serviceBox: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
});

export const dashboard = (theme) => ({
  containerProducts: {
    //border: "2px solid #336699",
    marginTop: '30px',
    marginBottom: '30px',
  },

  /* Products */
  box50: {
    position: 'relative',
    //border: "1px solid green",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 10px)',
      overflowY: 'hidden',
    },
    float: 'left',
    height: '460px',
  },
  box50image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  box50content: {
    position: 'relative !important',
    top: 0,
    left: 15,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    paddingBottom: '32px',
    height: '100%',
    maxHeight: '460px',
    width: 'calc(100% - 20px)',
  },
  box50contentLogo: {
    marginTop: 15,
    maxHeight: '70px',
    width: '100%',
    //border: "1px solid #ff0000"
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
      objectPosition: 'left',
    },
  },
  box100contentLogo: {
    marginTop: 15,
    maxHeight: '95px',
    width: '100%',
    //border: "1px solid #ff0000"
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%%',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  box100logo: {},
  box50logo: {
    height: '100%',
  },
  containerEven: {
    marginRight: '0px',
    marginLeft: '0px',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '0px',
      marginLeft: '10px',
    },
  },
  containerOdd: {
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0px',
      marginRight: '10px',
    },
  },

  /** Pages */
  containerPages: {
    //border: "2px solid #336699",
    marginTop: '30px',
    marginBottom: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
  box33: {
    flexBasis: 'calc(100% / 3)',
    maxWidth: 'calc(100% / 3)',
  },
  box33content: {
    marginBottom: '5px',
    marginTop: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },

  resetFloat: {
    clear: 'both',
  },

  card_50: {
    height: 460,
    position: 'relative',
    // padding: "30px 24px",
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
  },
  card_100: {
    // padding: "60px 120px",
    height: 460,
    position: 'relative',
    // padding: "30px 24px",
    // [theme.breakpoints.down("sm")]: {
    //     padding: "20px 24px",
    // },
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
  },
  boxText__100: {
    padding: '60px 120px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 24px',
    },
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      objectPosition: 'center',
    },
    backgroundColor: theme.customPalette.neutralGr02,
  },
  boxText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  boxText__50: {
    padding: '24px 24px 48px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },
  newLabel: {
    color: theme.customPalette.secondaryPinkP01,
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    display: 'block',
    backgroundColor: '#ff6671',
    color: '#fff',
    width: 'fit-content',
    padding: 8,
    paddingBottom: 0,
    display: 'inline-block',
    marginBottom: 8,
  },
  cardTitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    margin: '0',
  },
  cardTitle100: {
    color: '#fff',
    fontSize: 35,
    fontWeight: 'bold',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  cardTitle50: {
    color: '#fff',
    fontSize: 30,
    fontWeight: 'bold',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  btnLabel: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    // [theme.breakpoints.down("sm")]: {
    //     fontSize: 14,
    // },
  },
  categoryCard: {
    textDecoration: 'none',
  },
});

export const app = (theme) => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
  },
  alertCP: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#396dcf',
    flexDirection: 'row',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  odio: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'normal',
  },
  btnPopup: {
    fontSize: 16,
    maxWidth: '218px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  modal: {
    height: '100vh',
    '& .MuiDrawer-paper': {
      height: '100%',
    },
  },
  description: {
    fontSize: 14,
    color: '#3b4154',
    fontWeight: 'normal',
  },
  marketingBoxGDPR: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& p': {
      margin: 0,
    },
  },
  drawerContent: {
    width: '50vw',
    height: '50%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  drawnerHeaderTitle: {
    border: 'none',
    borderBottom: '1px solid' + theme.customPalette.neutralGr02,
    '& p': {
      fontSize: '20px',
      fontWeight: 500,
    },
  },
  modalContainer: {
    width: '100%',
    height: 'auto',
  },
  boxContent: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  stickyStack: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
  },
  main: {
    backgroundColor: '#fff',
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.customPalette.neutralGr01,

      paddingBottom: 0,
    },
  },
  mainTitleDialog: {
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 600,
    color: theme.customPalette.neutralGy05,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  headerTitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
  },
  fullScreenProgerss: {},
  iconTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.customPalette.primaryGreenG02,
    marginBottom: '-5px',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  whatsappContainer: {
    border: 0,
    position: 'fixed',
    zIndex: '50',
    bottom: '150px',
    right: 0,
    margin: '10px 20px',
  },
  messageUsButton_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
  },
  messageUsButton: {
    width: '100%',
    justifyContent: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    fontWeight: '500',
    lineHeight: 0,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    border: '1px solid transparent',
    borderRadius: '999rem',
    backgroundColor: 'rgb(37, 211, 102)',
    color: 'rgb(255, 255, 255)',
    fontSize: '15px',
    padding: '15px',
  },
  messageUsButton_logo: {
    marginRight: '8px',
  },
  messageUsButton_label: {
    border: '1px solid transparent',
    borderRadius: '999rem',
    backgroundColor: 'rgb(37, 211, 102)',
    color: 'rgb(255, 255, 255)',
    fontSize: '15px',
  },
});

export const userBanner = (theme) => ({
  root: {
    // minHeight: 233,
    color: 'white',
    fontFamily: 'Poppins',
    //backgroundColor: theme.customPalette.neutralGr02,
    backgroundColor: theme.customPalette.neutralGr01,
    [theme.breakpoints.down('xs')]: {
      // minHeight: 295,
    },
  },
  containerTabs: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #3b4154',
  },
  container: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5),
    },
  },
  paddingNoOrder: {
    paddingBottom: '16px',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: '20px',
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px',
    },
  },
  id: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.customPalette.primaryGreenG02,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  subtitle: {
    color: theme.customPalette.neutralGy05,
    fontSize: 16,
    fontWeight: 500,
  },
  grid: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  link: {
    textTransform: 'uppercase',
  },
});

export const publicContactForm = (theme) => ({
  contactForm: {
    fontFamily: 'Poppins',
  },
  dropzoneUploadBtnTitle: {
    wordBreak: 'break-all',
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },
  uploadTitle: {
    display: 'block',
    marginBottom: theme.spacing(2),
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  dragInputActive: {
    backgroundColor: theme.customPalette.neutralGr03,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  },
  dragInputDeactive: {
    backgroundColor: theme.customPalette.neutralGr01,
  },
  dropzone: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dragInput: {
    position: 'relative',
    width: '100%',
    border: '1px dashed' + theme.customPalette.secondaryYellowYButtons,
    height: '56px',
    transition: 'all 0.4s ease-out',
    transform: 'scale(1)',
    cursor: 'pointer',
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  icon: {
    marginRight: theme.spacing(1),
    transform: 'rotate(180deg)',
  },
  fileAccepted: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: 'Poppins',
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    fontFamily: 'Poppins',
  },
  smallText: {
    marginBottom: theme.spacing(0),
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
  },
  container: {
    padding: theme.spacing(10, 3, 13, 3),
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  upTitle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '500',
    display: 'block',
  },
  divider: {
    margin: theme.spacing(8, 0),
  },
  captchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  paddingRight: {
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
  paddingLeft: {
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  w100: {
    width: '100%',
  },
  subscriptionSelect: {},

  submitButton: {
    marginTop: theme.spacing(3),
  },
  submitButtonResponsive: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  placeholder: {
    color: '#aaa',
  },
  helpImage: {
    width: 56,
    height: 56,
  },
  helpTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'left!important',
  },
  helpTextWrapper: {
    marginLeft: 40,
  },
  helpSubTitle: {
    display: 'flex',
    fontSize: 16,
    textAlign: 'left!important',
  },
  helpLink: {
    color: theme.customPalette.secondaryYellowYButtons,
    fontWeight: 'bold',
  },
  helpTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 2,
  },
  helpContainer: {
    display: 'flex',
    minHeight: 114,
    background: theme.customPalette.neutralGr02,
    width: 1160,
    padding: '32px 40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 1208px)': {
      width: 'auto',
      padding: 16,
      marginLeft: 24,
      marginRight: 24,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  helpContent: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  videoSlider: {
    margin: '0 3px',
  },
  messageInput: {
    '& :placeholder': {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
    '& .MuiFilledInput-input': {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
    '& input': {
      '& .MuiInputBase-input.MuiFilledInput-input': {
        fontSize: 16,
        fontWeight: 'normal',
        fontFamily: 'Poppins',
        color: theme.customPalette.neutralGr04,
      },
    },
  },
  labelSelect: {
    displaY: 'block',
    textTransform: 'capitalize',
  },
  topicSelect: {
    '& .MuiSelect-select': {
      color: theme.customPalette.neutralGr04,
      fontSize: 16,
      fontWeight: 'normal',
      padding: '10px 20px',
      marginBottom: '-5px',
    },
    '& .MuiInputLabel-filled': {
      color: theme.customPalette.neutralGr04,
      fontSize: 16,
      fontWeight: 'normal',
      marginBottom: '-5px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(22px, 8px) scale(.55)',
    },
  },
});

export const profileInfo = (theme) => ({
  root: {
    width: '100%',
  },
  changePassContainer: {
    paddingTop: theme.spacing(6),
  },
  changePass: {
    color: theme.customPalette.secondaryYellowYButtons,
    fontSize: 16,
    fontWeight: 500,
    borderBottom: '1px solid' + theme.customPalette.secondaryYellowYButtons,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  inputProf: {
    width: '100%',
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: ' translate(15px, 12px) scale(0.55)',
    },
    '& .MuiFilledInput-input': {
      padding: '22px 12px 6px !important',
    },
  },
  indexSelect: {
    '& .MuiSelect-select': {
      padding: '22px 12px 6px !important',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
      marginBottom: '-5px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    width: '100%',
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: ' translate(15px, 12px) scale(0.55)',
    },
  },
  dateInput: {
    width: '100%',
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(15px, 12px) scale(0.55)',
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      opacity: 0.4,
      cursor: 'pointer',
    },
    '& .MuiFilledInput-input': {
      padding: '22px 12px 6px !important',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
  },
  panelActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: '600',
    color: theme.palette.neutralGy05,
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  col: {
    padding: theme.spacing(6, 7, 6, 7),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
  },
  colPass: {
    padding: theme.spacing(3, 7, 3, 7),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 3, 2),
    },
  },
  colTitle: {
    textTransform: 'capitalize',
  },
  avatarRoot: {
    height: '100%',
    width: '100%',
  },
  summaryContainer: {
    margin: theme.spacing(0),
    alignItems: 'center',
  },
  dataContainer: {
    backgroundColor: 'red',
  },
  horizontalDivider: {
    marginTop: theme.spacing(2),
  },
  errorPhone: {
    fontSize: 12,
    color: theme.customPalette.coral,
  },
  errorPass: {
    fontSize: 12,
    color: theme.customPalette.coral,
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    padding: '16px 32px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  profileContainer: {
    padding: '16px 32px',
    paddingBottom: '12px',
    paddingTop: '0',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
      paddingBottom: 'auto',
      paddingTop: 'auto',
    },
  },
  avatar: {
    width: '48px',
    height: '48px',
    marginBottom: '16px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  nameInfo: {
    paddingLeft: '0',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  border: {
    // border: "none",
    border: '1px solid' + theme.customPalette.neutralGr03,
    borderTop: 'none',
  },
  panelSummaryBorder: {
    // border: "none",
    [theme.breakpoints.up('sm')]: {
      // border: "1px solid" + theme.customPalette.secondaryYellowYButtons,
    },
  },
  containerProf: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  panelActionsMobile: {
    padding: 32,
  },
});

export const emailCommunication = (theme) => ({
  root: {
    width: '100%',
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: '600',
    color: theme.customPalette.neutralGy05,
    display: 'block',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {},
  },
  summaryContainer: {
    minHeight: 72,
  },
  details: {
    padding: '45px 57px',
  },
  formTitle: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.17,
  },
  formDescription: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.22,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
});

export const faqs = (theme) => ({
  faqs: {},

  upTitle: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    marginBottom: 0,
  },

  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '&:before': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  expandIcon: {
    width: 24,
    height: 24,
  },
  unselected: {
    color: theme.customPalette.neutralGr02,
    [theme.breakpoints.down('sm')]: {
      color: '#999999',
    },
  },
  selectedArrow: {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  rotateUp: {
    '-webkit-transform': 'rotate(-90deg)',
    '-ms-transform': 'rotate(-90deg)',
    transform: 'rotate(-90deg)',
  },
  rotateDown: {
    '-webkit-transform': 'rotate(90deg)',
    '-ms-transform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
  },
  imageOverride: {
    transform: 'rotate(90deg)',
    width: '16px',
    height: '16px',
    [theme.breakpoints.down('sm')]: {},
  },
  panelSummary: {
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      border: '1px solid' + theme.customPalette.secondaryYellowYButtons,
    },
  },
  panelDetails: {
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGr05,
    backgroundColor: theme.customPalette.neutralGr01,
  },
  unselectedArrow: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  faq: {
    background: `#fff !important`,
    borderRadius: 0,
    marginBottom: theme.spacing(2),
    '&:first-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '&:last-child': {
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.down('sm')]: {
      borderRight: `${theme.spacing(2)}px solid white`,
      borderLeft: `${theme.spacing(2)}px solid white`,
    },
  },
  faqRoot: {},
  faqHeading: {
    margin: theme.spacing(1, 1, 1, 0),
    fontWeight: theme.typography.fontWeightBold,
  },
  answer: {},
  categories: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  category: {
    border: '1px solid' + theme.customPalette.neutralGy05,
    paddingLeft: '24px',
    paddingRight: '24px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      border: 'none',
    },
    '& .MuiDivider-root': {
      display: 'none',
    },
  },
  categoryArrow: {
    width: 33,
    height: 31,
    marginRight: theme.spacing(1),
  },
  selected: {
    border:
      '1px solid' + theme.customPalette.secondaryYellowYButtons + '!important',
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
});

export const faqsPrivate = (theme) => ({
  mainUpTitle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
  },
  mainTitle: {
    fontFamily: 'Poppins',
    fontSize: 30,
    fontWeight: 500,
    color: theme.customPalette.primaryGreenG02,
    marginTop: 0,
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginBottom: theme.spacing(5),
    },
  },
  faqsContainer: {
    paddingLeft: theme.spacing(0),

    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(10),
    },
  },
  question: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  answer: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
    lineHeight: 1.17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  faq: {
    marginBottom: theme.spacing(2),
  },
  panelSummary: {
    border: 'none',
  },
  noSubTopic: {
    fontSize: 20,
    fontWeight: '500',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
});
